let $ = require("jquery");
window.jQuery = $;

$(document).ready(function () {

    let errors_alert = $('.errors_alert')[0];
    if (errors_alert) {
        errors_alert.parentElement.scrollIntoView();

        $('.error_alert').on('click', function (event) {
            event.stopPropagation();
        });

        $('.errors_alert').on('click', function (event) {
            errors_alert.remove();
        });
    }

    $('.remove_alert_error').on('click', function (event) {
        event.currentTarget.closest('.error_alert').remove();

        if (!$('.error_alert')[0]) {
            $('.errors_alert').remove();
        }
    });

});
